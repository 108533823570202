// STYLE FORMULAIRES

import React from "react";
import styled from "styled-components";

export const FormGroup = styled.div`
	color: palevioletred;
    display: block;
	width: 300px;
	margin: 50px auto;
`;

export const Label = styled.label`
	margin-bottom: 0.1em;
	color: white;
    display: block;
	font-weight: bold;
`;

export const Label1 = styled.label`
	margin-bottom: 0.1em;
	color: #FF9999;
    display: block;
`;

export const Label2 = styled.label`
	color: black;
    display: block;
	font-size: 10px;
`;

export const Label3 = styled.label`
	color: #ff6f28;
    display: block;
	font-size: 0.9em;
`;

export const Label4 = styled.label`
	color: black;
    display: block;
	font-size: 0.5em;
`;

export const Input = styled.input`
	padding: 0.5em;
	color: black;
	background: white;
	border: none;
	border-radius: 2px;
	width: 100%;
	margin-bottom: 0.5em;
`;

export const Message = styled.label`
	margin-bottom: 0.5em;
	color: palevioletred;
    display: block;
`;
