// composant principal - Carte

// import librairies
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import XMLParser from 'react-xml-parser';
import {
    GoogleMap,
    useJsApiLoader,
    InfoWindow,
    Marker,
    Polygon
} from '@react-google-maps/api';
import {
    setEtape,
    setMessage,
    setEchelle,
    setLongitudeSite,
    setLatitudeSite,
    setFenetreTableau,
    setTableauDonnees
} from "../features/cineSlice";
import { Label2 } from "../utils/Forms";
import { useForm } from 'react-hook-form';

// clés API
const keyVM = process.env.REACT_APP_VM_key      // clé Via Michelin
const dbVM = process.env.REACT_APP_VM_db        // base de données Via Michelin

// variables diverses
let distances = [];
let compteurPole = 0;
let localisationPoles = [];

// Composant Menu déroulant
const Dropdown = ({ label, value, options, onChange }) => {
    return (
        <label>
            {label}
            <select value={value} onChange={onChange}>
                {options.map((option) => (
                    <option value={option.value} key={option.value}>{option.label}</option>
                ))}
            </select>
        </label>
    );
};

// COMPOSANT
//------------------------------------------------------------------------------

function Carte() {

    // ETATS
    //------------------------------------------------------------------------------

    // états locaux
    const [siteEtude, setSiteEtude] = useState({ lat: 47, lng: 2 });            // marker du site d'étude
    const [fenetreSite, setFenetreSite] = useState(false);                      // ouverture de la fenetre d'info site d'étude, si true
    const [fenetreZone, setFenetreZone] = useState(false);                      // ouverture de la fenetre d'info d'une zone, si true
    const [fenetreParam, setFenetreParam] = useState(false);                    // ouverture de la fenetre des paramètres, si true
    const [fenetreSeuils, setFenetreSeuils] = useState(false);                  // ouverture de la fenetre des seuils isochrones, si true
    const [fenetreCine, setFenetreCine] = useState(false);                      // numéro du cinéma activé dans fenetre d'info
    const [drawMarker, setDrawMarker] = useState(true);                         // possibilité de déplacer le marqueur site si true
    const [zones, setZones] = useState([]);                                     // fond de carte
    const [zoneSelect, setZoneSelect] = useState(null);                         // zone sélectionnée sur la carte
    const [choixNomZone, setChoixNomZone] = React.useState(1);                  // choixNomZone
    const [listChoixZone, setListChoixZone] = React.useState(null);             // options pour menu déroulant choix Zone
    const [listCines, setListCines] = useState([]);                             // liste des cinémas
    const [zoneRef, setZoneRef] = React.useState({
        zoneId: "",
        zoneNom: "",
        zoneLongi: 0,
        zoneLati: 0
    });                                                                         // zone de référence
    const [seuils, setSeuils] = React.useState([10, 20, 30]);                   // seuils des isochrones
    const [opacity, setOpacity] = useState(0.8);                                // opacité de la carte
    const [nbSeancesRef, setNbSeancesRef] = useState(10000);                    // nombre de séances du site de référence
    const [zoneMarker, setZoneMarker] = useState({ lat: 47, lng: 2 });          // marker d'une zone cliquée

    // états locaux - paramétrage étude
    const [semexpl, setSemexpl] = useState(52);                                 // nombre de semaines d'exploitation des cinémas
    const [coef_multi, setCoef_multi] = useState(0.2);                          // coefficient de majoration de l'offre multiplexe                        
    const [dist_freq, setDist_freq] = useState([1, 0.6, 0.4]);                  // coefficients de distance pour indice de fréquentation
    const [dist_tradi, setDist_tradi] = useState([0.9, 0.5, 0.3]);              // coefficients de distance pour fréquentation cinémas traditionnels 
    const [poleloign, setPoleloign] = useState(0.1);                            // coefficient d'absorption des pôles éloignés
    const [publicext, setPublicext] = useState(0.03);                           // coefficient de majoration public extérieur à la ZIC
    const [pcsTP, setPcsTP] = useState([83.10, 49.30, 79.60, 65.00]);           // coefficients PCS TP
    const [pcsFR, setPcsFR] = useState([4.7, 5.3, 5.3, 3.6]);                   // coefficients PCS FR
    const [ageTP, setAgeTP] = useState([82.30, 70.30, 48.20]);                  // coefficients Age TP
    const [ageFR, setAgeFR] = useState([4.8, 4.4, 6.8]);                        // coefficients Age FR
    const [dipTP, setDipTP] = useState([78.30, 60.95]);                         // coefficients Diplome TP
    const [dipFR, setDipFR] = useState([8.4, 4.0]);                             // coefficients Diplome FR
    const [distInter1, setDistInter1] = useState(30);                           // distance d'interaction par défaut
    const [distInter2, setDistInter2] = useState(20);                           // distance d'interaction région parisienne

    // états partagés dans le store
    const etape = useSelector((state) => state.cine.etape);                     // numéro de l'étape
    const message = useSelector((state) => state.cine.message);                 // message lié à l'étape en cours
    const longitude = useSelector((state) => state.cine.longitudeSite);         // longitude du site d'étude
    const latitude = useSelector((state) => state.cine.latitudeSite);           // latitude du site d'étude
    const echelle = useSelector((state) => state.cine.echelle);                 // échelle territoriale : true = communale, false = infra-communale
    const fenetreTableau = useSelector((state) => state.cine.fenetreTableau);   // ouverture du tableau des données

    // INITIALISATIONS
    //------------------------------------------------------------------------------
    const dispatch = useDispatch();
    const { handleSubmit, register, setValue } = useForm();

    function onSubmitParametres(data) {
        console.log("mise à jour")
        setSemexpl(parseInt(data.semexpl))
        setCoef_multi(Number(data.coef_multi));
        setDist_freq([Number(data.dist_freq[0]), Number(data.dist_freq[1]), Number(data.dist_freq[2])])
        setDist_tradi([Number(data.dist_tradi[0]), Number(data.dist_tradi[1]), Number(data.dist_tradi[2])])
        setPoleloign(Number(data.poleloign))
        setPublicext(Number(data.publicext))
        setPcsTP([Number(data.pcsTP[0]), Number(data.pcsTP[1]), Number(data.pcsTP[2]), Number(data.pcsTP[3])])
        setPcsFR([Number(data.pcsFR[0]), Number(data.pcsFR[1]), Number(data.pcsFR[2]), Number(data.pcsFR[3])])
        setAgeTP([Number(data.ageTP[0]), Number(data.ageTP[1]), Number(data.ageTP[2])])
        setAgeFR([Number(data.ageFR[0]), Number(data.ageFR[1]), Number(data.ageFR[2])])
        setDipTP([Number(data.dipTP[0]), Number(data.dipTP[1])])
        setDipFR([Number(data.dipFR[0]), Number(data.dipFR[1])])
        setDistInter1(parseInt(data.setDistInter1))
        setDistInter2(parseInt(data.setDistInter2))

        setFenetreParam(false);
    }
    function onSubmitSeuils(data) {
        setFenetreSeuils(false);
        setSeuils([parseInt(data.seuils[0]), parseInt(data.seuils[1]), parseInt(data.seuils[2])]);
        dispatch(setEtape("202.1"))
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_Google_Maps
    });

    const onLoad = React.useCallback(function callback(map) {
        setDrawMarker(true)
    }, []);

    const choixZone = (event) => {
        setChoixNomZone(event.target.value)
        setZoneRef({
            zoneId: listChoixZone[event.target.value - 1].codeZone,
            zoneNom: listChoixZone[event.target.value - 1].label,
            zoneLongi: listChoixZone[event.target.value - 1].longitude,
            zoneLati: listChoixZone[event.target.value - 1].latitude
        });
    };

    // GESTION DES CHANGEMENTS D'ETAPES
    //------------------------------------------------------------------------------
    useEffect(() => {
        if (etape === "100") {
            console.log("Etape 100 - init");
        }
        if (etape === "101") {
            console.log("Etape 101 - marqueur créé");
            setDrawMarker(true)
        }
        if (etape === "102") {
            console.log("Etape 102 - chargement des cinémas");
            getCines();
            findZone()
        }
        if (etape === "103B") {
            console.log("Etape 103B - ajout de nouveaux cinémas sur la carte");
            dispatch(setMessage("Vous pouvez ajouter de nouveaux cinémas en double-cliquant sur la carte"))
            setDrawMarker(false);
        }
        if (etape === "103") {
            console.log("Etape 103 - chargement fond de carte");
            dispatch(setMessage("Chargement du fond de carte en cours, merci de patienter ..."))
            sendGetRequest();
        }

        if (etape === "104") {
            console.log("Etape 104 - fond chargé, cinémas chargés");
            dispatch(setMessage("ETAPE 1 : Choix du site d'étude. Vous pouvez maintenant lancer le calcul de la ZIC."));
        }
        if (etape === "200") {
            dispatch(setMessage("ETAPE 2 : Choix de la ZIC - calcul des isochrones en cours ..."));
            distances = [];
            callVM(longitude, latitude, "201");
        }
        if (etape === "201") {
            console.log("Etape 201");
            dispatch(setMessage("ETAPE 2 : Choix de la ZIC"));
            traceIso();
            dispatch(setEtape("202"));
        }
        if (etape === "202") {
            dispatch(setMessage("ETAPE 2 : Choix de la ZIC - vous pouvez sélectionner les poles de concurrence"));
            console.log("etape 202")
        }

        if (etape === "202.1") {
            dispatch(setMessage("ETAPE 2 : Choix de la ZIC - vous pouvez sélectionner les poles de concurrence"));
            actualisationIso();
            dispatch(setEtape("202"))
        }

        if (etape === "300") {
            dispatch(setMessage("ETAPE 3 : Choix des poles de concurrence"));
            decoupeZIC();
        }

        if (etape === "301") {
            dispatch(setMessage("ETAPE 3 : Choix des poles de concurrence"));
        }

        if (etape === "302") {
            dispatch(setMessage("ETAPE 3 : Calcul des distances aux poles de concurrence"));
            actuIsoCine();
            compteurPole += 1;
            distances = [];
            if (compteurPole < localisationPoles.length) {
                calculIsoCine();
                dispatch(setEtape("301"));
            } else {
                dispatch(setEtape("303"))
            }
        }

        if (etape === "303") {
            dispatch(setMessage("ETAPE 3 : Calcul des distances aux poles de concurrence terminé"));
            actualisationIso()
        }

    }, [etape]);

    // GESTION DES INTERACTIONS CARTE
    //------------------------------------------------------------------------------

    // création du marqueur site d'étude
    const addMarker = (coords) => {
        setSiteEtude(coords);
        dispatch(setLongitudeSite(coords.lng))
        dispatch(setLatitudeSite(coords.lat))
        dispatch(setEtape("101"))
    }


    /*
    const findZone = async () => {
        try {
            let url = ""
            if (echelle === true) {
                url = "https://www.cine-info.fr/requetes/comref_communal.php?longi=";
            } else {
                url = "https://www.cine-info.fr/requetes/comref_infracom.php?longi=";
            }
            url = url + longitude + "&lati=" + latitude;
            const res = await axios.get(url);
            const listZ = res.data.split('&');
            let options = [];
            for (let i = 0; i < listZ.length - 1; i++) {
                const elementZone = listZ[i].split('+');
                options.push({
                    label: elementZone[2],
                    value: (i + 1),
                    codeZone: elementZone[1],
                    longitude: Number(elementZone[3]),
                    latitude: Number(elementZone[4])
                });
            }
            setListChoixZone(options);
            setZoneRef({
                zoneId: options[0].codeZone,
                zoneNom: options[0].label,
                zoneLongi: options[0].longitude,
                zoneLati: options[0].latitude
            });
        }
        catch (err) {
            // Si erreur de chargement
            console.error(err);
        }
    };
    */
    // création d'un cinéma
    const addCinema = async (coords) => {
        //console.log(coords)
        //console.log(listCines)

        let urlCom = "https://www.cine-info.fr/requetes/comref_communal.php?longi=" + coords.lng + "&lati=" + coords.lat;
        let urlIris = "https://www.cine-info.fr/requetes/comref_infracom.php?longi=" + coords.lng + "&lati=" + coords.lat;

        const resCom = await axios.get(urlCom);
        const resIris = await axios.get(urlIris);

        const listCom = resCom.data.split('&');
        const elementCom = listCom[0].split('+');
        console.log(elementCom[2] + " / " + elementCom[1])

        const listIris = resIris.data.split('&');
        const elementIris = listIris[0].split('+');
        console.log(elementIris[2] + " / " + elementIris[1])


        const identifiant = parseInt(Math.random() * 10000 + 1000);
        const unCine = {
            id: String(identifiant),
            name: String(identifiant),
            typo: "Complexe",
            proprio: "_",
            codecom: "_",
            code_zone1: elementCom[1],
            lib_zone1: "_",
            code_zone2: elementIris[1],
            lib_zone2: "_",
            code_zone3: elementIris[1],
            lib_zone3: "_",
            ecrans: "5",
            faut: "500",
            seances: "5000",
            entrees: "50000",
            recet: "_",
            pme: "_",
            sem: "53",
            select: "0",
            ponderation: "1.0",
            position: {
                lat: Number(coords.lat),
                lng: Number(coords.lng)
            }
        };
        setListCines(listCines => [...listCines, unCine]);
        //setEtape("301");
    }

    // mise à jour de la localisation du site après déplacement du marqueur
    let onMarkerDragEnd = (e) => {
        dispatch(setLongitudeSite(e.latLng.lng()))
        dispatch(setLatitudeSite(e.latLng.lat()))
        setSiteEtude({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }

    // chargement du fond de carte en cours
    function valideEtape() {
        dispatch(setEtape("102"));
    }

    // chargement du fond de carte
    function validCinemas() {
        dispatch(setEtape("103"));
    }

    // validation de la ZIC
    function valideZIC() {
        console.log("Valide la ZIC");
        dispatch(setEtape("300"));
    }

    // réinitialisation complète pour nouvelle étude
    function nouvelleEtude() {
        console.log("Nouvelle étude");
        window.location.reload(false);
    };

    // change échelle communale / infa-communale
    const changeEchelle = () => {
        dispatch(setEchelle(!echelle));
    };

    // choix des seuils isochrones
    function choixSeuils(numseuil) {
        if (numseuil === 1) {
            setSeuils([10, 20, 30])
            setValue("seuils[0]", 10);
            setValue("seuils[1]", 20);
            setValue("seuils[2]", 30);
        };
        if (numseuil === 2) {
            setSeuils([15, 20, 25])
            setValue("seuils[0]", 15);
            setValue("seuils[1]", 20);
            setValue("seuils[2]", 25);
        };
        if (numseuil === 3) {
            setSeuils([15, 25, 35])
            setValue("seuils[0]", 15);
            setValue("seuils[1]", 25);
            setValue("seuils[2]", 35);
        };
        if (numseuil === 4) {
            setSeuils([15, 25, 40])
            setValue("seuils[0]", 15);
            setValue("seuils[1]", 25);
            setValue("seuils[2]", 40);
        };
        actualisationIso();
    };

    // modification des paramètres de l'étude
    function parametresEtudes() {
        setFenetreParam(!fenetreParam);
    }

    // modification des seuils d'isochrones
    function seuilsIsochrones() {
        setFenetreSeuils(!fenetreSeuils);
    }

    // click sur site d'étude
    function clickSite() {
        setFenetreSite(!fenetreSite);
    }

    // click sur une zone
    function clickZone(idZone) {
        setFenetreZone(!fenetreZone);
        setZoneSelect(zones.find(record => record.zoneId === idZone));
        const z = zones.find(record => record.zoneId === idZone);
        const zlong = z.zoneLati;
        const zlat = z.zoneLongi;
        setZoneMarker({ lat: z.zoneLati, lng: z.zoneLongi });
    }

    // change le nombre de sances du site de référence
    function changeNbSeances(e) {
        setNbSeancesRef(parseInt(e.target.value))
    }

    // change la distance temps d'une commune
    function changeDistemps(zoneSelect, e) {
        let nouvellesZones = []
        for (const uneZone of zones) {
            if (parseInt(uneZone.zoneId) === parseInt(zoneSelect)) {
                uneZone.distemps = parseInt(e.target.value)
                uneZone.opacity = 0.8;
            }
        }
        setZones(nouvellesZones);
        actualisationIso();
    }

    // ouvre le tableau des données
    function ouvertureTableau() {
        calculMarPot();
        dispatch(setFenetreTableau(!fenetreTableau));
    }

    function sortFunction(a, b) {
        if (a[0] === b[0]) {
            return 0;
        }
        else {
            return (a[0] < b[0]) ? -1 : 1;
        }
    }

    // CHARGEMENT DU FOND DE CARTE
    //------------------------------------------------------------------------------

    const sendGetRequest = async () => {
        try {
            let url = ""
            if (echelle === true) {
                url = "https://www.cine-info.fr/requetes/voisinage_communal.php?longi=";
            } else {
                url = "https://www.cine-info.fr/requetes/voisinage_infracom.php?longi=";
            }
            url = url + longitude + "&lati=" + latitude;
            console.log(url)
            const res = await axios.get(url);
            const traces = new XMLParser().parseFromString(res.data);
            dispatch(setEtape("104"));
            dispatch(setMessage("Chargement du fond de carte en cours ..."));
            console.log("Nombre de zones dans le voisinage : "+traces.getElementsByTagName('com').length)
            // Balayage des zones pour création du fond de carte
            for (let i = 0; i < traces.getElementsByTagName('com').length; i++) {
                console.log("Compteur zone "+i)
                const contour = traces.getElementsByTagName('com')[i].children[7].value.split("!");
                const uneZone = [];
                for (let j = 0; j < Number(contour[0]); j++) {
                    uneZone.push({
                        lat: Number(contour[j * 2 + 2]),
                        lng: Number(contour[j * 2 + 1])
                    });
                }
                const zoneId = Number(traces.getElementsByTagName('com')[i].children[0].value);
                const zoneNom = traces.getElementsByTagName('com')[i].children[1].value;
                const zoneLongi = Number(traces.getElementsByTagName('com')[i].children[4].value);
                const zoneLati = Number(traces.getElementsByTagName('com')[i].children[5].value);
                const zoneDepart = Number(traces.getElementsByTagName('com')[i].children[12].value);
                const zoneTemps = Number(traces.getElementsByTagName('com')[i].children[13].value);
                const zoneKm = Number(traces.getElementsByTagName('com')[i].children[14].value);
                const zoneOis = Number(traces.getElementsByTagName('com')[i].children[15].value);
                const zoneActif = Number(traces.getElementsByTagName('com')[i].children[16].value);

                setZones((zones) => zones.concat([{
                    zoneId,
                    zoneNom,
                    zoneLongi,
                    zoneLati,
                    zoneDepart,
                    zoneTemps,
                    zoneKm,
                    zoneOis,
                    zoneActif,
                    uneZone
                }]))
            }
        } catch (err) {
            // Si erreur de chargement
            console.error(err);
        }
    };

    // IDENTIFICATION DE LA ZONE DE REFERENCE
    //------------------------------------------------------------------------------

    const findZone = async () => {
        try {
            let url = ""
            if (echelle === true) {
                url = "https://www.cine-info.fr/requetes/comref_communal.php?longi=";
            } else {
                url = "https://www.cine-info.fr/requetes/comref_infracom.php?longi=";
            }
            url = url + longitude + "&lati=" + latitude;
            const res = await axios.get(url);
            const listZ = res.data.split('&');
            let options = [];
            for (let i = 0; i < listZ.length - 1; i++) {
                const elementZone = listZ[i].split('+');
                options.push({
                    label: elementZone[2],
                    value: (i + 1),
                    codeZone: elementZone[1],
                    longitude: Number(elementZone[3]),
                    latitude: Number(elementZone[4])
                });
            }
            setListChoixZone(options);
            setZoneRef({
                zoneId: options[0].codeZone,
                zoneNom: options[0].label,
                zoneLongi: options[0].longitude,
                zoneLati: options[0].latitude
            });
        }
        catch (err) {
            // Si erreur de chargement
            console.error(err);
        }
    };

    // CHARGE LES CINEMAS
    //------------------------------------------------------------------------------

    // Chargement des cinémas
    const getCines = async () => {
        try {
            let url = "https://www.cine-info.fr/requetes/cinemas.php?longi=";
            url = url + longitude.toString() + "&lati=" + latitude.toString();
            const res = await axios.get(url);
            const xmlCines = new XMLParser().parseFromString(res.data);
            for (let i = 0; i < xmlCines.children.length; i++) {
                const unCine = {
                    id: xmlCines.children[i].children[1].value,
                    name: xmlCines.children[i].children[0].value,
                    typo: xmlCines.children[i].children[2].value,
                    proprio: xmlCines.children[i].children[3].value,
                    codecom: xmlCines.children[i].children[4].value,
                    code_zone1: xmlCines.children[i].children[5].value,
                    lib_zone1: xmlCines.children[i].children[6].value,
                    code_zone2: xmlCines.children[i].children[7].value,
                    lib_zone2: xmlCines.children[i].children[8].value,
                    code_zone3: xmlCines.children[i].children[9].value,
                    lib_zone3: xmlCines.children[i].children[10].value,
                    ecrans: xmlCines.children[i].children[11].value,
                    faut: xmlCines.children[i].children[12].value,
                    seances: xmlCines.children[i].children[13].value,
                    entrees: xmlCines.children[i].children[14].value,
                    recet: xmlCines.children[i].children[15].value,
                    pme: xmlCines.children[i].children[16].value,
                    sem: xmlCines.children[i].children[17].value,
                    select: xmlCines.children[i].children[20].value,
                    ponderation: xmlCines.children[i].children[21].value,
                    position: {
                        lat: Number(xmlCines.children[i].children[19].value),
                        lng: Number(xmlCines.children[i].children[18].value)
                    }
                }
                setListCines(listCines => [...listCines, unCine]);
            }
            dispatch(setEtape("103B"))

        } catch (err) {
            // Si erreur de chargement
            console.error(err);
        }
    }

    // ouverture fenetre info ciné
    const clickCine = (marker) => {
        if (marker === fenetreCine) {
            return;
        }
        setFenetreCine(marker);
    };

    // CHARGE LES DONNEES SOCIO-ECONOMIQUES
    //------------------------------------------------------------------------------

    const socioeco = async (nzones) => {
        try {
            let url = ""
            if (echelle === true) {
                url = "https://www.cine-info.fr/requetes/socioeco_communal.php?longi=";
            } else {
                url = "https://www.cine-info.fr/requetes/socioeco_infracom.php?longi=";
            }
            url = url + longitude + "&lati=" + latitude;
            const res = await axios.get(url);
            const xmlSocioeco = new XMLParser().parseFromString(res.data);

            // ajout des données socioéco dans le tableau des zones
            let nouvellesZones = []
            let compt = 0;
            for (const uneZone of nzones) {
                for (const dataSocio of xmlSocioeco.children) {
                    if (parseInt(dataSocio.attributes.INSEE) === uneZone.zoneId) {
                        //console.log(dataSocio.attributes)
                        if (uneZone.zoneActif === 1) {
                            uneZone.socioeco = dataSocio.attributes;
                            nouvellesZones.push(uneZone)
                        }
                    }
                }
            }
            setZones(nouvellesZones)
            dispatch(setEtape("301"));
        } catch (err) {
            // Si erreur de chargement
            console.error(err);
        }
    }

    // CHARGE UNE ETUDE
    //------------------------------------------------------------------------------

    const chargeEtude = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            dispatch(setMessage("Données chargées"));
            const donnees = JSON.parse(e.target.result);

            setSiteEtude(donnees.siteEtude.siteEtude);
            setFenetreSite(donnees.fenetreSite.fenetreSite);
            setFenetreZone(donnees.fenetreZone.fenetreZone);
            setFenetreParam(donnees.fenetreParam.fenetreParam);
            setFenetreSeuils(donnees.fenetreSeuils.fenetreSeuils);
            setDrawMarker(donnees.drawMarker.drawMarker);
            setZones(donnees.zones.zones);
            setZoneSelect(donnees.zoneSelect.zoneSelect);
            setChoixNomZone(donnees.choixNomZone.choixNomZone);
            setListChoixZone(donnees.listChoixZone.listChoixZone);
            setZoneRef(donnees.zoneRef.zoneRef);
            setSeuils(donnees.seuils.seuils);
            setListCines(donnees.listCines.listCines);
            setOpacity(donnees.opacity.opacity);
            setNbSeancesRef(donnees.nbSeancesRef.nbSeancesRef);

            setSemexpl(donnees.semexpl.semexpl);
            setCoef_multi(donnees.coef_multi.coef_multi);
            setDist_freq(donnees.dist_freq.dist_freq);
            setDist_tradi(donnees.dist_tradi.dist_tradi);
            setPoleloign(donnees.poleloign.poleloign);
            setPublicext(donnees.publicext.publicext);
            setPcsTP(donnees.pcsTP.pcsTP);
            setPcsFR(donnees.pcsFR.pcsFR);
            setAgeTP(donnees.ageTP.ageTP);
            setAgeFR(donnees.ageFR.ageFR);
            setDipTP(donnees.dipTP.dipTP);
            setDipFR(donnees.dipFR.dipFR);
            setDistInter1(donnees.distInter1.distInter1);
            setDistInter2(donnees.distInter2.distInter2);

            dispatch(setEtape(donnees.etape.etape));
            dispatch(setMessage(donnees.message.message));
            dispatch(setLongitudeSite(donnees.longitude.longitude));
            dispatch(setLatitudeSite(donnees.latitude.latitude));
            dispatch(setEchelle(donnees.echelle.echelle));
        };
    };

    // SAUVEGARDE UNE ETUDE
    //------------------------------------------------------------------------------

    // objet sauvegarde des données
    const obj = {
        siteEtude: { siteEtude },
        fenetreSite: { fenetreSite },
        fenetreZone: { fenetreZone },
        fenetreParam: { fenetreParam },
        fenetreSeuils: { fenetreSeuils },
        drawMarker: { drawMarker },
        zones: { zones },
        zoneSelect: { zoneSelect },
        choixNomZone: { choixNomZone },
        listChoixZone: { listChoixZone },
        zoneRef: { zoneRef },
        seuils: { seuils },
        listCines: { listCines },
        opacity: { opacity },
        nbSeancesRef: { nbSeancesRef },

        semexpl: { semexpl },
        coef_multi: { coef_multi },
        dist_freq: { dist_freq },
        dist_tradi: { dist_tradi },
        poleloign: { poleloign },
        publicext: { publicext },
        pcsTP: { pcsTP },
        pcsFR: { pcsFR },
        ageTP: { ageTP },
        ageFR: { ageFR },
        dipTP: { dipTP },
        dipFR: { dipFR },
        distInter1: { distInter1 },
        distInter2: { distInter2 },

        etape: { etape },
        message: { message },
        longitude: { longitude },
        latitude: { latitude },
        echelle: { echelle }
    };

    // blob données sauvegardées
    const blob = new Blob([JSON.stringify(obj, null, 2)], { type: 'application/json' });

    // enregistrement des données
    const sauverEtude = async (blob) => {
        const a = document.createElement('a');
        a.download = 'sauvegarde_etude.json';
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };

    // CALCUL DE LA ZIC
    //------------------------------------------------------------------------------

    function calculZIC() {
        dispatch(setEtape("200"));
    };

    function adresse_serveur_POI_VM(var_Long, var_Lat, var_zone, var_groupe, var_rayon) {
        let requeteVM = "https://apir.viamichelin.com/apir/1/findPOIByRoad.xml?db=" + dbVM
        requeteVM += "&lg=fra&center=" + String(var_Long) + ":" + String(var_Lat) + "&dist=50000&nb=90&time="
        requeteVM += String(var_rayon) + "&" + var_zone + "&" + var_groupe
        requeteVM += "&authkey=" + keyVM;
        return requeteVM
    };

    const sendPOIRequest = async (url_requete) => {
        const res = await axios.get(url_requete);
        const mesPOI = new XMLParser().parseFromString(res.data);
        return mesPOI
    };


    // appel de Via Michelin
    async function callVM(longiVM, latiVM, nbEtape) {
        /* Rappel de l'API Via Michelin, fonction findPOIByRoad :
     
                http://apir.viamichelin.com/apir/1/findPOIByRoad.xml?db=                    nom de la base de données, dbVM
                &lg=fra                                                                     pays France
                &center=LONGITUD:LATITUDE                                                   longitude et latitude du point de référence
                &dist=50000                                                                 rayon de recherche autour du point de référence
                &nb=100                                                                     nombre de POI attendus
                &time=2760                                                                  temps maximal pour atteindre les POI en secondes
                &c1=X                                                                       numéro de la zone, c1=1 : découpage communal
                ou c1=2 : découpage infracommunal
                &c2=X [ou c3, c4, c5, c6]                                                   numéro du groupe,  c2=X : nombre de 1 à 5 pour  5 zones
                 c3=X : nombre de 1 à 8 pour  8 zones                                                                                         c4=X : nombre de 1 à 10 pour 10 zones                                                                                         ou c6=X : nombre de 1 à 20 pour 20 zones
                &authkey=keyVM                                                              clé d'utilisation de l'API VM
        */

        // Nombre de zones dans le voisinage:
        const NbZoneVois = zones.length                                                     // nombre de zones du voisinage

        let decompteNbDistances = 0

        //console.log("Nombre de zones du voisinage : " + NbZoneVois)
        //console.log("Echelle du découpage: " + echelle)
        let critereEchelle = ""
        if (echelle === true) { critereEchelle = "c1=1" } else { critereEchelle = "c1=2" }   // choix de l'échelle communale/infra-communale

        let criterPaquet = "c2=1"
        let prefixPaquet = "c2="
        let nbPaquets = 5
        criterPaquet = "c2=1"
        nbPaquets = 5
        const url_requete1 = adresse_serveur_POI_VM(longiVM, latiVM, critereEchelle, criterPaquet, 2760)
        const truc1 = await sendPOIRequest(url_requete1)
        for (let i = 0; i < truc1.children[4].children.length; i++) {
            distances.push({
                codeZone: truc1.children[4].children[i].children[2].children[5].children[5].value,
                nomCom: truc1.children[4].children[i].children[2].children[3].value,
                distsec: truc1.children[4].children[i].children[1].value,
                distmet: truc1.children[4].children[i].children[0].value
            })
        }
        decompteNbDistances += truc1.children[4].children.length;
        console.log("5 groupes: " + truc1.children[4].children.length)

        if (truc1.children[4].children.length > 85) {
            criterPaquet = "c3=1"
            prefixPaquet = "c3="
            nbPaquets = 8
            const url_requete2 = adresse_serveur_POI_VM(longiVM, latiVM, critereEchelle, criterPaquet, 2760)
            const truc2 = await sendPOIRequest(url_requete2)
            for (let i = 0; i < truc2.children[4].children.length; i++) {
                distances.push({
                    codeZone: truc2.children[4].children[i].children[2].children[5].children[5].value,
                    nomCom: truc2.children[4].children[i].children[2].children[3].value,
                    distsec: truc2.children[4].children[i].children[1].value,
                    distmet: truc2.children[4].children[i].children[0].value
                })
            }
            decompteNbDistances += truc2.children[4].children.length;
            console.log("8 groupes: " + truc2.children[4].children.length)

            if (truc2.children[4].children.length > 85) {
                criterPaquet = "c4=1"
                prefixPaquet = "c4="
                nbPaquets = 10
                const url_requete3 = adresse_serveur_POI_VM(longiVM, latiVM, critereEchelle, criterPaquet, 2760)
                const truc3 = await sendPOIRequest(url_requete3)
                for (let i = 0; i < truc3.children[4].children.length; i++) {
                    distances.push({
                        codeZone: truc3.children[4].children[i].children[2].children[5].children[5].value,
                        nomCom: truc3.children[4].children[i].children[2].children[3].value,
                        distsec: truc3.children[4].children[i].children[1].value,
                        distmet: truc3.children[4].children[i].children[0].value
                    })
                }
                decompteNbDistances += truc3.children[4].children.length;
                console.log("10 groupes: " + truc3.children[4].children.length)

                if (truc3.children[4].children.length > 85) {
                    criterPaquet = "c5=1"
                    prefixPaquet = "c5="
                    nbPaquets = 15
                    const url_requete4 = adresse_serveur_POI_VM(longiVM, latiVM, critereEchelle, criterPaquet, 2760)
                    const truc4 = await sendPOIRequest(url_requete4)
                    for (let i = 0; i < truc4.children[4].children.length; i++) {
                        distances.push({
                            codeZone: truc4.children[4].children[i].children[2].children[5].children[5].value,
                            nomCom: truc4.children[4].children[i].children[2].children[3].value,
                            distsec: truc4.children[4].children[i].children[1].value,
                            distmet: truc4.children[4].children[i].children[0].value
                        })
                    }
                    decompteNbDistances += truc4.children[4].children.length;
                    console.log("15 groupes: " + truc4.children[4].children.length)

                    if (truc4.children[4].children.length > 85) {
                        criterPaquet = "c6=1"
                        prefixPaquet = "c6="
                        nbPaquets = 20
                        const url_requete5 = adresse_serveur_POI_VM(longiVM, latiVM, critereEchelle, criterPaquet, 2760)
                        const truc5 = await sendPOIRequest(url_requete5)
                        for (let i = 0; i < truc5.children[4].children.length; i++) {
                            distances.push({
                                codeZone: truc5.children[4].children[i].children[2].children[5].children[5].value,
                                nomCom: truc5.children[4].children[i].children[2].children[3].value,
                                distsec: truc5.children[4].children[i].children[1].value,
                                distmet: truc5.children[4].children[i].children[0].value
                            })
                        }
                        decompteNbDistances += truc5.children[4].children.length;
                        console.log("20 groupes: " + truc5.children[4].children.length)
                    }
                }
            }
        }

        console.log("Echelle retenue :" + criterPaquet + " soit " + nbPaquets + " paquets")

        for (let compteurPaquet = 2; compteurPaquet <= nbPaquets; compteurPaquet++) {
            console.log("compteurPaquet :" + compteurPaquet)
            criterPaquet = prefixPaquet + String(compteurPaquet);
            console.log("criterPaquet : " + criterPaquet)
            const url_requete = adresse_serveur_POI_VM(longiVM, latiVM, critereEchelle, criterPaquet, 2760)
            console.log("url_requete : " + url_requete)
            const truc = await sendPOIRequest(url_requete)

            console.log("compteur: " + truc.children[4].children.length)
            for (let i = 0; i < truc.children[4].children.length; i++) {
                distances.push({
                    codeZone: truc.children[4].children[i].children[2].children[5].children[5].value,
                    nomCom: truc.children[4].children[i].children[2].children[3].value,
                    distsec: truc.children[4].children[i].children[1].value,
                    distmet: truc.children[4].children[i].children[0].value
                })
            }
            decompteNbDistances += truc.children[4].children.length;
            //console.log(criterPaquet + " > " + truc.children[4].children.length)
        }
        dispatch(setEtape(nbEtape));
        dispatch(setMessage("Calcul terminé"));
    }

    // TRACE DES ISOCHRONES
    //------------------------------------------------------------------------------

    // premier tracé des isochrones, avec actualisation des distances temps
    function traceIso() {
        let nouvellesZones = []
        //console.log(distances)
        for (var uneZoneColor of zones) {
            for (var xi = 0; xi < distances.length; xi++) {
                if (parseInt(uneZoneColor.zoneId) === parseInt(distances[xi].codeZone)) {
                    uneZoneColor.opacity = opacity;
                    uneZoneColor.distemps = Math.round(Number(distances[xi].distsec) / 60)
                    uneZoneColor.zoneKm = Number(distances[xi].distmet)
                    uneZoneColor.nomcom = distances[xi].nomCom;
                    uneZoneColor.color = "#FCE48B"; // jaune
                    if (parseInt(uneZoneColor.distemps) <= parseInt(seuils[2])) { uneZoneColor.color = "#B8CAF1" } // bleu clair}
                    if (parseInt(uneZoneColor.distemps) <= parseInt(seuils[1])) { uneZoneColor.color = "#6A8EE1" } // bleu plus foncé
                    if (parseInt(uneZoneColor.distemps) <= parseInt(seuils[0])) { uneZoneColor.color = "#0A1632" } // bleu très foncé
                    //console.log("==> " + uneZoneColor.zoneId + " : " + parseInt(uneZoneColor.distemps))
                }
            }
            nouvellesZones.push(uneZoneColor);
        }
        //console.log("iso tracée")
        //console.log(nouvellesZones)
        setZones(nouvellesZones)
    }

    // actualisation des isochrones
    function actualisationIso() {
        let nouvellesZones = []
        if (zones.length !== 0) {
            for (const uneZoneColor of zones) {
                uneZoneColor.color = "#FCE48B"; // jaune
                if (parseInt(uneZoneColor.distemps) <= parseInt(seuils[2])) { uneZoneColor.color = "#B8CAF1" } // bleu clair}
                if (parseInt(uneZoneColor.distemps) <= parseInt(seuils[1])) { uneZoneColor.color = "#6A8EE1" } // bleu plus foncé
                if (parseInt(uneZoneColor.distemps) <= parseInt(seuils[0])) { uneZoneColor.color = "#0A1632" } // bleu très foncé
                nouvellesZones.push(uneZoneColor);
                //console.log("**> " + uneZoneColor.zoneId + " : " + parseInt(uneZoneColor.distemps))
            }
            setZones(nouvellesZones);
        }
    }

    function actuIsoCine() {
        let nouveauxCines = []
        for (const unCine of listCines) {
            // sélectionne le pole dont les isochrones viennent d'être calculées
            if (unCine.id === localisationPoles[compteurPole].id) {
                let distancePole = []
                // balaye toutes les communes de la ZIC
                for (const uneCom of zones) {
                    let distanceTemps = 100;
                    let distanceKm = 100000;

                    // balaye toutes les communes de la zone d'influence du pole
                    for (const uneComCine of distances) {
                        // vérifie que la commune est dans la ZIC
                        if (parseInt(uneComCine.codeZone) === uneCom.zoneId) {
                            distanceTemps = Math.round(parseInt(uneComCine.distsec) / 60);
                            distanceKm = parseInt(uneComCine.distmet);
                        }
                    }
                    distancePole.push({
                        codeZone: uneCom.zoneId,
                        nomZone: uneCom.zoneNom,
                        distemps: distanceTemps,
                        distkm: distanceKm
                    })
                }
                unCine.distPole = distancePole;
                nouveauxCines.push(unCine);

                // colorisation de la carte
                let nouvellesZones = [];
                for (const uneZoneColor of zones) {
                    let tempoDistance = 0;
                    for (const tempoDist of distancePole) {
                        if (tempoDist.codeZone === uneZoneColor.zoneId) {
                            tempoDistance = tempoDist.distemps
                        }
                    }
                    uneZoneColor.color = "#FCE48B"; // jaune
                    if (tempoDistance <= parseInt(seuils[2])) { uneZoneColor.color = "#B8CAF1" } // bleu clair}
                    if (tempoDistance <= parseInt(seuils[1])) { uneZoneColor.color = "#6A8EE1" } // bleu plus foncé
                    if (tempoDistance <= parseInt(seuils[0])) { uneZoneColor.color = "#0A1632" } // bleu très foncé
                    nouvellesZones.push(uneZoneColor);
                }
                setZones(nouvellesZones);
            } else {
                nouveauxCines.push(unCine);
            }
        }
        setListCines(nouveauxCines);
    }

    // découpe de la ZIC
    function decoupeZIC() {
        let nouvellesZones = []
        for (const elemZone of zones) {
            if (parseInt(Number(elemZone.distemps)) <= seuils[2]) {
                nouvellesZones.push(elemZone);
            }
        }
        setZones(nouvellesZones)
        socioeco(nouvellesZones);
    }

    // CHOIX DES POLES DE CONCURRENCE
    //------------------------------------------------------------------------------

    function choixPole(e) {
        let nouveauxCines = []
        for (const unCine of listCines) {
            if (unCine.id === e) {
                if (unCine.select === "0") {
                    unCine.select = "1"
                }
                else { unCine.select = "0" }
            }
            nouveauxCines.push(unCine);
        }
        setListCines(nouveauxCines);
    }

    function ponder(e, e1) {
        let nouveauxCines = [];
        for (const unCine of listCines) {
            if (unCine.id === e) {
                unCine.ponderation = e1.target.value;
            }
            nouveauxCines.push(unCine);
        }
        setListCines(nouveauxCines);
    }


    // ACTUALISATION DU DESCRTIPTIF CINEMA
    //------------------------------------------------------------------------------

    function actuDataCine(nomVariable, id, e) {
        console.log(nomVariable)
        console.log(id)
        console.log(e.target.value)
        let nouveauxCines = [];

        for (const unCine of listCines) {
            if (nomVariable == "name") {
                if (unCine.id === id) {unCine.name = e.target.value;}
            }
            if (nomVariable == "typo") {
                if (unCine.id === id) {unCine.typo = e.target.value;}
            }
            if (nomVariable == "code_zone1") {
                if (unCine.id === id) {unCine.code_zone1 = e.target.value;}
            }
            if (nomVariable == "code_zone2") {
                if (unCine.id === id) {unCine.code_zone2 = e.target.value; unCine.code_zone3 = e.target.value;}
            }
            if (nomVariable == "ecrans") {
                if (unCine.id === id) {unCine.ecrans = e.target.value;}
            }
            if (nomVariable == "faut") {
                if (unCine.id === id) {unCine.faut = e.target.value;}
            }
            if (nomVariable == "seances") {
                if (unCine.id === id) {unCine.seances = e.target.value;}
            }
            if (nomVariable == "entrees") {
                if (unCine.id === id) {unCine.entrees = e.target.value;}
            }
            if (nomVariable == "sem") {
                if (unCine.id === id) {unCine.sem = e.target.value;}
            }
            nouveauxCines.push(unCine);
        }
        setListCines(nouveauxCines);
        console.log(nouveauxCines)
    }

    // ALGORITHME DE CALCUL DU MP
    //------------------------------------------------------------------------------

    function calculZonesPoles() {
        console.log("Calcul du MP")
        dispatch(setMessage("FIN DE L'ETAPE 3 : Calcul des distances aux poles de concurrence, veuillez patienter ..."));
        distances = [];
        compteurPole = 0;
        localisationPoles = [];
        for (const unCine of listCines) {
            if (unCine.select === "1") {
                localisationPoles.push({ lat: unCine.position.lat, lng: unCine.position.lng, id: unCine.id });
            }
        };
        calculIsoCine();
    }

    function calculIsoCine() {
        dispatch(setMessage("FIN DE L'ETAPE 3 : Calcul des distances aux poles de concurrence, veuillez patienter ..."));
        callVM(localisationPoles[compteurPole].lng, localisationPoles[compteurPole].lat, "302");
    }

    function calculMP() {
        console.log("Calcul du Marché Potentiel")
        //console.log(zones)
        //console.log(listCines)
        //console.log(pcsTP)
        //alert("Le Calcul du Marché Potentiel, bientot sur vos écrans.")
        calculMarPot();
    }

    /* Calcul du marché potentiel

    matrix : (n = numéro de commune)

        matrix[n][ 0] : distance temps classée par ordre croissant
        matrix[n][ 1] : nom de la zone
        matrix[n][ 2] : code de la zone
        matrix[n][ 3] : population
        matrix[n][ 4] : zone (de 1 à 3)
        matrix[n][ 5] : marché potentiel théorique
        matrix[n][ 6] : taux de fréquentation  hebdomadaire théorique
        matrix[n][ 7] : autres cinémas : fréquentation
        matrix[n][ 8] : autres cinémas : fréquentation prise en compte
        matrix[n][ 9] : autres cinémas : part de marché absorbée
        matrix[n][10] : marché absorbé par les poles éloignés
        matrix[n][11] : public extérieur à la ZIC
        matrix[n][12] : marché potentiel final
        matrix[n][13] : part de marché %
        matrix[n][14] : marché capté par pôles concurrentiels
        matrix[n][15] : population attirée par pôles concurrents
        matrix[n][16] : indice de fréquentation
        matrix[n][17][p] : interaction avec les poles (p = numéro du pole)

            matrix[n][17][p][ 0] : id du cinéma
            matrix[n][17][p][ 1] : distance à la commune n (ou 100 si > 30 mn)
            matrix[n][17][p][ 2] : population en interaction avec le pole
            matrix[n][17][p][ 3] : population attirée par le pole
            matrix[n][17][p][ 4] : marché absorbé par le pole
            matrix[n][17][p][ 5] : nombre d'écrans du pôle
            matrix[n][17][p][ 6] : pondération du pôle
            matrix[n][17][p][ 7] : nombre de séances du pôle

    */

    function calculMarPot() {

        // ----------------------------------------
        // initialisation de la matrice des données
        console.log("CALCUL MPT")
        var matrix = [];

        // boucle de balayage des zones
        for (const uneZone of zones) {
            if (uneZone.zoneActif === 1) {

                // initialisation des valeurs
                let varMarPot =
                    [
                        0,               //matrix[n][ 0] : distance temps classée par ordre croissant
                        "",              //matrix[n][ 1] : nom de la zone
                        "",              //matrix[n][ 2] : code de la zone
                        0,               //matrix[n][ 3] : population
                        "",              //matrix[n][ 4] : zone (de 1 à 3)
                        0,               //matrix[n][ 5] : marché potentiel théorique
                        0,               //matrix[n][ 6] : taux de fréquentation théorique hebdomadaire théorique
                        0,               //matrix[n][ 7] : autres cinémas : fréquentation
                        0,               //matrix[n][ 8] : autres cinémas : fréquentation prise en compte
                        0,               //matrix[n][ 9] : autres cinémas : part de marché absorbée
                        0,               //matrix[n][10] : part de marché absorbée par les poles éloignés
                        0,               //matrix[n][11] : public extérieur à la ZIC
                        0,               //matrix[n][12] : marché potentiel final
                        0,               //matrix[n][13] : part de marché %
                        0,               //matrix[n][14] : marché capté par pôles concurrentiels
                        0,               //matrix[n][15] : population attirée par pôles concurrents
                        0,               //matrix[n][16] : indice de fréquentation
                        []               //matrix[n][17][p] : interaction avec les poles (p = numéro du pôle)
                    ]
                matrix.push(varMarPot)
            }
        }

        // ------------------------------------
        // calcul du marché potentiel théorique

        var marchePot = [];

        // balayage de toutes les zones
        for (const uneZone of zones) {
            if (uneZone.zoneActif === 1) {

                // Calcul du Marché Potentiel
                let marpotAge = 0;
                let marpotPcs = 0;
                let marpotDip = 0;
                let marpot = 0;
                // MP selon Age
                marpotAge =
                    Number(uneZone.socioeco.pop) *
                    (
                        Number(ageTP[0]) *
                        Number(ageFR[0]) *
                        Number(uneZone.socioeco.age6)
                        / 100
                        +
                        Number(ageTP[1]) *
                        Number(ageFR[1]) *
                        Number(uneZone.socioeco.age25)
                        / 100
                        +
                        Number(ageTP[2]) *
                        Number(ageFR[2]) *
                        Number(uneZone.socioeco.age60)
                        / 100
                    )
                    / 100;

                // MP selon Pcs
                marpotPcs =
                    Number(uneZone.socioeco.pop) *
                    (
                        Number(pcsTP[0]) *
                        Number(pcsFR[0]) *
                        Number(uneZone.socioeco.pcsetud)
                        / 100
                        +
                        Number(pcsTP[1]) *
                        Number(pcsFR[1]) *
                        Number(uneZone.socioeco.pcsret)
                        / 100
                        +
                        Number(pcsTP[2]) *
                        Number(pcsFR[2]) *
                        Number(uneZone.socioeco.pcsp)
                        / 100
                        +
                        Number(pcsTP[3]) *
                        Number(pcsFR[3]) *
                        Number(uneZone.socioeco.pcsm)
                        / 100
                    )
                    / 100;

                // MP selon niveau de diplome
                marpotDip =
                    Number(uneZone.socioeco.pop) *
                    (
                        Number(dipTP[0]) *
                        Number(dipFR[0]) *
                        Number(uneZone.socioeco.dips)
                        / 100
                        +
                        Number(dipTP[1]) *
                        Number(dipFR[1]) *
                        Number(uneZone.socioeco.ndips)
                        / 100
                    )
                    / 100;
                // ancien calcul, avec niveau de diplome :
                // marpot = parseInt((marpotAge + marpotPcs + marpotDip) / 3);

                // nouveau calcul, sans niveau de diplome :
                marpot = parseInt((marpotAge + marpotPcs) / 2);

                // Correction facteur multiplexe
                marpot = Math.round(marpot * (1 + coef_multi));

                // Correction facteur Zone
                var zoneIso = 1;
                if (uneZone.distemps > seuils[0]) zoneIso = 2;
                if (uneZone.distemps > seuils[1]) zoneIso = 3;

                if (zoneIso == 1) { marpot = parseInt(marpot * dist_freq[0]) }

                if (zoneIso == 2) { marpot = parseInt(marpot * dist_freq[1]) }

                if (zoneIso == 3) { marpot = parseInt(marpot * dist_freq[2]) }

                console.log(parseInt(uneZone.distemps) + " /zone : " + zoneIso + "   /   " + marpot + "   /   " + uneZone.zoneId + "  " + coef_multi + "    " + seuils + "  " + dist_freq)

                // ajout des données dans le tableau
                marchePot.push([parseInt(uneZone.distemps), uneZone.nomcom, uneZone.zoneId, uneZone.socioeco.pop, marpot]);
            }
        }

        // trie le tableau
        marchePot.sort(sortFunction);

        // Ajout des zones isochrones selon seuils
        for (let i = 0; i < marchePot.length; i++) {
            let nomZone = "ZONE 3";
            if (marchePot[i][0] <= seuils[1]) { nomZone = "ZONE 2"; }
            if (marchePot[i][0] <= seuils[0]) { nomZone = "ZONE 1"; }
            marchePot[i].push(nomZone)
        }

        // ----------------------------------------------------
        // intégration des premières données triées dans matrix
        let i1 = 0;
        for (const uneZone of marchePot) {
            matrix[i1][0] = uneZone[0];
            matrix[i1][1] = uneZone[1];
            matrix[i1][2] = uneZone[2];
            matrix[i1][3] = uneZone[3];
            matrix[i1][4] = uneZone[5];
            matrix[i1][5] = uneZone[4];
            i1++;
        }

        // ------------------------------------------
        // données relatives aux pôles de concurrence

        // crée le tableau des libellés de cinémas Poles de concurrence
        let cineLibelle = [];
        //console.log(listCines)
        for (const unCine of listCines) {
            if (unCine.select === "1") {
                cineLibelle.push({
                    id: unCine.id,
                    name: unCine.name,
                    distPole: unCine.distPole,
                    nbEcrans: unCine.ecrans,
                    ponderation: unCine.ponderation,
                    seances: unCine.seances
                })
            }
        }

        // crée le tableau des distances aux poles de concurrence
        i1 = 0;
        for (const uneZone of marchePot) {
            //console.log(uneZone)
            let lesPoles = [];
            for (const unCine of cineLibelle) {
                let unPole = [0, 0, "non calculé", "non calculé", "non calculé", 0, "", ""];
                for (const subZone of unCine.distPole) {
                    if (parseInt(subZone.codeZone) === parseInt(uneZone[2])) {
                        unPole[0] = unCine.id;
                        unPole[1] = subZone.distemps;
                        unPole[5] = unCine.nbEcrans;
                        unPole[6] = unCine.ponderation;
                        unPole[7] = unCine.seances;
                        if (subZone.distemps > 30) {
                            unPole[1] = 100;
                        }
                    }
                }
                lesPoles.push(unPole)
            }
            matrix[i1][17] = lesPoles;
            i1++;
        }

        //console.log(matrix)

        // traitement Loi de Reilly
        // ========================

        // balayage des communes
        for (let i_cmarpoth = 0; i_cmarpoth < matrix.length; i_cmarpoth++) {

            var attractivité_poles = new Array(); 		// Matrice des attractivités par pôle (0 = site de référence, puis par cinéma).
            var numero_pole = 0; 						// numéro du pôle        

            // distance de la commune courante au site de référence minorée à 5 minutes
            var dist_polref = parseInt(matrix[i_cmarpoth][0])
            if (dist_polref < 5) dist_polref = 5;

            // attractivité du pôle de référence
            // soit : nombre de séances du projet divisé par le carré de la distance-temps au pole

            attractivité_poles[0] = nbSeancesRef / (dist_polref * dist_polref);
            var facteur_normalisation = attractivité_poles[0];

            // boucle de balayage des pôles concurrentiels
            for (const unCine of matrix[i_cmarpoth][17]) {
                numero_pole++;

                // limite de la zone d'influence du pôle selon nb d'écrans et département
                var limite_zone_pole = new Number();
                if (parseInt(unCine[5]) < 8) {
                    limite_zone_pole = distInter2
                } else {
                    limite_zone_pole = distInter1;
                }

                // région parisienne = départements : 75 (Paris), 92 (Haut de Seine), 94 (Val de Marne) et 93 (Seine Saint Denis)
                if (
                    (matrix[i_cmarpoth][2].toString().substring(0, 2) == "75") ||
                    (matrix[i_cmarpoth][2].toString().substring(0, 2) == "92") ||
                    (matrix[i_cmarpoth][2].toString().substring(0, 2) == "93") ||
                    (matrix[i_cmarpoth][2].toString().substring(0, 2) == "94")) { limite_zone_pole = distInter2; }


                var dist_com_pole = Number(unCine[1]);
                if (dist_com_pole < 5) dist_com_pole = 5; 			// La distance temps est au minimum de 5 minutes

                if (dist_com_pole <= limite_zone_pole) {
                    attractivité_poles[numero_pole] = Number(unCine[6]) *
                        Number(unCine[7]) / (dist_com_pole * dist_com_pole);
                    facteur_normalisation += attractivité_poles[numero_pole];
                } else {
                    attractivité_poles[numero_pole] = 0;
                };

                // console.log("limite_zone_pole :" + limite_zone_pole + " pour  cinéma " + matrix[i_cmarpoth][1] + " avec nb écrans = " + parseInt(unCine[5]))

                // vérification sur commune test 501291103
                if (matrix[i_cmarpoth][2] == "x501291103") {
                    console.log("---------")
                    console.log("Vérification sur " + matrix[i_cmarpoth][1])
                    console.log("Population " + matrix[i_cmarpoth][3])
                    console.log("Distance de la commune au site de référence : " + dist_polref);
                    console.log("Distance de la commune au pôle de concurrence : " + unCine[1]);
                    console.log(matrix[i_cmarpoth]);
                    console.log("Nombre d'écran du pole : " + unCine[5])
                    console.log("Ponderation du pole : " + unCine[6])
                    console.log("Nombre de seances du pole : " + unCine[7])
                    console.log("Code depart : " + matrix[i_cmarpoth][2].toString().substring(0, 2))
                }
            }

            // normalisation de la matrice
            for (var i_normalisation = 0; i_normalisation < attractivité_poles.length; i_normalisation++) {
                attractivité_poles[i_normalisation] = attractivité_poles[i_normalisation] / facteur_normalisation;
            }

            //console.log("Matrice des attractivités après normalisation : ")
            //console.log(attractivité_poles);

            // populations et marchés attirées par les pôles concurrentiels
            numero_pole = 0;
            for (const unCine of matrix[i_cmarpoth][17]) {   // Boucle par pôle concurrentiel
                matrix[i_cmarpoth][17][numero_pole][2] = parseInt(matrix[i_cmarpoth][3]);
                matrix[i_cmarpoth][17][numero_pole][3] = parseInt(parseInt(matrix[i_cmarpoth][3]) * attractivité_poles[numero_pole + 1] );
                matrix[i_cmarpoth][17][numero_pole][4] = parseInt(parseInt(matrix[i_cmarpoth][5]*(1-poleloign)) * attractivité_poles[numero_pole + 1] );

                // vérification sur commune test 501291103
                if (matrix[i_cmarpoth][2] === "x501291103") {
                    console.log("+++++++++++++")
                    console.log("Vérification sur " + matrix[i_cmarpoth][1])
                    console.log("attractivité pole : " + unCine[0] + " : " + attractivité_poles[numero_pole + 1]);
                    console.log("population de la zone :" + matrix[i_cmarpoth][3])
                    console.log("MP de la zone :" + matrix[i_cmarpoth][5])
                    console.log("population attirée :" + matrix[i_cmarpoth][3])
                    console.log("part de de marché absordée :")
                    console.log(matrix[i_cmarpoth][17][numero_pole])

                }
                numero_pole++;
            }

            // taux de fréquentation hebdomadaire moyen théorique :
            // 100 * marché potentiel théorique  /( population de la commune * nombre de semaines d'exploitation)
            // le nb de semaines d'exploitation semexpl est par défaut 52, modifiable dans les paramètres 
            matrix[i_cmarpoth][6] = parseInt(1000 * ((matrix[i_cmarpoth][5]) / (matrix[i_cmarpoth][3] * semexpl))) / 10;
        }

        // calcul de la part de marché absorbée par les cinémas classiques
        // balayage des cinés traditionnels
        console.log("Cinés tradi :");
        for (const unCine of listCines) {
            if (unCine.select !== "1") {
                // balayage des communes
                for (let ii = 0; ii < matrix.length; ii++) {
                    if (
                        (String(unCine.code_zone1) === String(matrix[ii][2])) ||
                        (String(unCine.code_zone2) === String(matrix[ii][2]))
                    ) {
                        var frequentation_pec = new Number;
                        if (String(matrix[ii][4]) === "ZONE 1") { frequentation_pec = Number(unCine.entrees * dist_tradi[0]) }
                        if (String(matrix[ii][4]) === "ZONE 2") { frequentation_pec = Number(unCine.entrees * dist_tradi[1]) }
                        if (String(matrix[ii][4]) === "ZONE 3") { frequentation_pec = Number(unCine.entrees * dist_tradi[2]) }

                        matrix[ii][7] += parseInt(unCine.entrees);
                        matrix[ii][8] += parseInt(frequentation_pec);
                        matrix[ii][9] += parseInt(frequentation_pec * Number(unCine.ponderation));
                    }
                }
            }
        }

        // calcul des autres indicateurs
        // balayage des communes
        for (let ii = 0; ii < matrix.length; ii++) {

            // part de marché absorbée par pôles éloignés
            // soit : MP théorique * coefficient poleloign
            matrix[ii][10] = parseInt(matrix[ii][5] * poleloign);

            // Marché potentiel final sans public extérieur à la zone
            // soit : MP théorique [5] - Pdm cinéma autres que poles [9] - Pdm poles éloignés [10] - somme de pdm absorbées par poles
            matrix[ii][12] = matrix[ii][5] - matrix[ii][9] - matrix[ii][10];

            for (let jj = 0; jj < matrix[ii][17].length; jj++) {
                matrix[ii][12] -= parseInt(matrix[ii][17][jj][4]);
            }

            // public extérieur à la ZIC
            // soit MP final sans public extérieur à la zone[12] * coefficient publicext
            matrix[ii][11] = parseInt(matrix[ii][12] * publicext);

            // Marché potentiel final avec majoration du public extérieur
            // soit Marché potentiel final sans public extérieur à la zone [12] + public extérieur à la ZIC [11]
            matrix[ii][12] += matrix[ii][11];
        }

        // calcul du marché potentiel de l'ensemble de la ZIC
        var marpottot = 0;
        for (let ii = 0; ii < matrix.length; ii++) {
            marpottot += parseInt(matrix[ii][12])
        }
        console.log("Marché potentiel total : " + marpottot)

        // calcul des taux :
        // balayage des communes
        for (let ii = 0; ii < matrix.length; ii++) {

            // calcul des données sur poles de concurrence
            var popPole = 0;
            var pdmPole = 0;
            for (let jj = 0; jj < matrix[ii][17].length; jj++) {
                popPole += matrix[ii][17][jj][3];
                pdmPole += matrix[ii][17][jj][4];
            }
            //console.log(" popPole : " + popPole)
            //console.log(" pdmPole : " + pdmPole)

            // part de marché (%)
            // ( mp final - mp extérieur à la ZIC ) totalité des mp
            matrix[ii][13] = 0;
            if (marpottot != 0) { matrix[ii][13] = parseInt(10000 * ((matrix[ii][12] - matrix[ii][11]) / marpottot)) / 100 }

            // part de marché captée par poles conc.(%)
            // 
            matrix[ii][14] = parseInt(10000 * pdmPole / matrix[ii][5]) / 100;

            // part de de la pop. att. par poles conc.(%)
            matrix[ii][15] = parseInt(10000 * popPole / matrix[ii][3]) / 100;

            // indice de fréquentation
            // soit : (mp final [12] - public extérieur zic[11])/ population [3]
            matrix[ii][16] = parseInt(100 * (matrix[ii][12] - matrix[ii][11]) / matrix[ii][3]) / 100
        }
        dispatch(setTableauDonnees({ matrix: matrix, libelleCines: cineLibelle }));
    }

    function checking() {
        console.log("Checking----")
        console.log("nbSeancesRef :" + nbSeancesRef)
    }

    // INTERFACE UTILISATEUR
    //------------------------------------------------------------------------------

    return isLoaded ? (
        <div>
            <div className="new-post-modal">
                <img src={process.env.PUBLIC_URL + "images/logo.png"} width="100" alt=""></img>
                <Label2>version 1.63 - etape : {etape} </Label2>
                <br></br>
                <span ><strong>{message}</strong> </span>
                <br></br><br></br>

                {(etape !== "100") && (
                    <div>
                        <Label2>Longitude du site d'étude : {longitude}</Label2>
                        <Label2>Latitude du site d'étude :  {latitude}</Label2>
                        <Label2><strong>Zone de référence :  {zoneRef.zoneNom} / {zoneRef.zoneId}</strong></Label2>
                        <br></br>
                    </div>
                )}

                {(etape === "100") && (
                    <div>
                        <Label2>Longitude du site d'étude : -</Label2>
                        <Label2>Latitude du site d'étude :  -</Label2>
                        <Label2>Zone de référence :  -</Label2>
                        <br></br>
                    </div>
                )}

                {(etape === "100") && (
                    <>
                        <input type="file" id="filePicker" onChange={chargeEtude} />
                        <br></br><br></br>
                    </>
                )}

                {(etape === "101") && (
                    <div >
                        <input type="radio" checked={echelle} onChange={changeEchelle} />
                        Découpage communal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <br></br>
                        <input type="radio" checked={!echelle} onChange={changeEchelle} />
                        Découpage commune + Iris
                        <br></br><br></br>
                    </div>
                )}

                {/*
                <><button
                    style={{
                        border: 10,
                        padding: 7,
                        borderRadius: 7
                    }}
                    onClick={() => checking()}
                >Test
                </button> &nbsp;
                </>
                */}

                {(
                    (etape === "303") ||
                    (etape === "303")
                )
                    && (
                        <><button
                            style={{
                                border: 10,
                                padding: 7,
                                borderRadius: 7
                            }}
                            onClick={() => ouvertureTableau()}
                        >Tableau des données
                        </button> &nbsp;
                        </>
                    )}

                {(
                    (etape === "101") ||
                    (etape === "102") ||
                    (etape === "103B") ||
                    (etape === "103") ||
                    (etape === "104") ||
                    (etape === "202") ||
                    (etape === "301") ||
                    (etape === "302") ||
                    (etape === "303")
                )
                    && (
                        <><button
                            style={{
                                background: "rgb(200, 200, 200)",
                                border: 10,
                                padding: 7,
                                borderRadius: 7
                            }}
                            onClick={() => nouvelleEtude()}
                        >Nouvelle étude
                        </button> &nbsp;
                        </>
                    )}

                {(
                    (etape !== "100")
                )
                    && (
                        <><button
                            style={{
                                background: "rgb(200, 200, 200)",
                                border: 10,
                                padding: 7,
                                borderRadius: 7
                            }}
                            onClick={() => sauverEtude(blob)}
                        >Sauver
                        </button> &nbsp;
                        </>
                    )}

                {(
                    (etape === "104") ||
                    (etape === "202") ||
                    (etape === "301")
                )
                    && (
                        <><button
                            style={{
                                background: "rgb(200, 200, 200)",
                                border: 10,
                                padding: 7,
                                borderRadius: 7
                            }}
                            onClick={() => parametresEtudes()}
                        >Paramètres
                        </button> &nbsp;
                        </>
                    )}

                {(
                    (etape === "202")
                )
                    && (
                        <>
                            <button
                                style={{
                                    background: "rgb(200, 200, 200)",
                                    border: 10,
                                    padding: 7,
                                    borderRadius: 7
                                }}
                                onClick={() => seuilsIsochrones()}
                            >Seuils
                            </button> &nbsp;
                        </>
                    )}

                {(
                    (etape === "104")
                )
                    && (
                        <>
                            <button
                                style={{
                                    border: 10,
                                    padding: 7,
                                    borderRadius: 7
                                }}
                                onClick={() => calculZIC()}
                            >Calcul de la ZIC
                            </button> &nbsp;
                        </>
                    )}

                {(etape === "101") && (
                    <> <button
                        style={{
                            border: 10,
                            padding: 7,
                            borderRadius: 7
                        }}
                        onClick={() => valideEtape()}
                    >Valider le positionnement
                    </button>
                    </>
                )}

                {(etape === "103B") && (
                    <> <button
                        style={{
                            border: 10,
                            padding: 7,
                            borderRadius: 7
                        }}
                        onClick={() => validCinemas()}
                    >Valider les cinémas
                    </button>
                    </>
                )}


                {(etape === "202") && (
                    <> <button
                        style={{
                            border: 10,
                            padding: 7,
                            borderRadius: 7
                        }}
                        onClick={() => valideZIC()}
                    >Valider la ZIC
                    </button>
                    </>
                )}

                {(
                    (etape === "301") ||
                    (etape === "301")
                ) && (
                        <> <button
                            style={{
                                border: 10,
                                padding: 7,
                                borderRadius: 7
                            }}
                            onClick={() => calculZonesPoles()}
                        >Calcul des zones concurrentielles
                        </button>
                        </>
                    )}

                {(
                    (etape === "101") ||
                    (etape === "102") ||
                    (etape === "103B") ||
                    (etape === "103") ||
                    (etape === "104") ||
                    (etape === "200") ||
                    (etape === "202") ||
                    (etape === "202") ||
                    (etape === "300") ||
                    (etape === "301") ||
                    (etape === "302") ||
                    (etape === "303")
                ) && (
                        <>
                            <br></br><br></br>
                        </>)}
            </div>

            {/* ----- carte google maps ----- */}
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '400px' }}
                center={siteEtude}
                zoom={6.5}
                onLoad={onLoad}
                onClick={(e) => drawMarker ? addMarker(e.latLng.toJSON()) : null}
                onDblClick={(e) => (etape !== "103B") ? console.log("clic neutre") : addCinema(e.latLng.toJSON())}


                //</div>     {console.log("hop"); addCinema(e.latLng.toJSON())}}

                //onClick={(e) => (etape !== "301dummy") ? clickZone(zone.zoneId) : addCinema(e.latLng.toJSON())}


                options={{
                    disableDoubleClickZoom: true
                }}
            >

                {zones ? (
                    zones.map((zone) => {
                        return (
                            <Polygon
                                key={zone.zoneId}
                                path={zone.uneZone}
                                options={{
                                    strokeColor: "#000000",
                                    strokeOpacity: 0.9,
                                    fillColor: zone.color,
                                    fillOpacity: zone.opacity,
                                    strokeWeight: 0.9,
                                    icons: [
                                        {
                                            offset: "0",
                                            repeat: "20px"
                                        }
                                    ],
                                }}
                                onClick={(e) => (etape !== "301dummy") ? clickZone(zone.zoneId) : addCinema(e.latLng.toJSON())}
                            >
                            </Polygon>
                        )
                    })
                ) : null}

                {fenetreZone ? (
                    <InfoWindow position={zoneMarker} onCloseClick={() => setFenetreZone(false)}>
                        <div style={{ color: 'black', fontSize: 9 }}>
                            <div >
                                Zone sélectionnée : {zoneSelect.zoneNom} ({zoneSelect.zoneId} )
                                <br></br>
                                {(etape === "202") ? (
                                    <>
                                        Distance temps :
                                        <input
                                            type="number"
                                            defaultValue={zoneSelect.distemps}
                                            onChange={(e) => changeDistemps(zoneSelect.zoneId, e)}
                                        />
                                        <br></br><br></br>
                                    </>
                                ) :
                                    <>
                                        Distance temps : {zoneSelect.distemps}
                                        <br></br><br></br>
                                    </>}
                            </div>
                        </div>
                    </InfoWindow>
                ) : null}

                {(etape !== "100") ? (
                    <Marker
                        icon={{
                            url: 'images/star.png',
                            anchor: new window.google.maps.Point(10, 10),
                            scaledSize: new window.google.maps.Size(20, 20)
                        }}
                        draggable={drawMarker}
                        position={siteEtude}
                        onDragEnd={(e) => onMarkerDragEnd(e)}
                        onClick={(e) => clickSite()}
                    >
                        {fenetreSite ? (
                            <InfoWindow onCloseClick={() => setFenetreSite(false)}>
                                <div style={{ color: 'black', fontSize: 12 }}>
                                    Site d'étude
                                    <br></br><br></br>
                                    Nombre de séances :<br></br>
                                    <label>
                                        <input
                                            type="number"
                                            defaultValue={nbSeancesRef}
                                            onChange={(e) => changeNbSeances(e)}
                                        />
                                    </label><br></br>

                                    {((etape === "104") && listChoixZone) ? (
                                        <>
                                            <br></br><br></br>
                                            <Dropdown
                                                options={listChoixZone}
                                                value={choixNomZone}
                                                onChange={choixZone}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            </InfoWindow>
                        ) : null}
                    </Marker>
                ) : null}

                {listCines.map(({
                    id,
                    name,
                    typo,
                    proprio,
                    code_zone1,
                    lib_zone1,
                    code_zone2,
                    lib_zone2,
                    ecrans,
                    faut,
                    seances,
                    entrees,
                    recet,
                    pme,
                    sem,
                    select,
                    ponderation,
                    position
                }) => (
                    <Marker
                        key={id}
                        position={position}
                        draggable={(etape === "103B") ? true : false}
                        icon={{
                            url:

                                ((typo === "Multiplexe") && (select === "0")) ? 'images/multiplexe.png' :
                                    ((typo === "Multiplexe") && (select === "1")) ? 'images/multiplexeSelect.png' :
                                        ((typo === "Complexe") && (select === "0")) ? 'images/complexe.png' :
                                            ((typo === "Complexe") && (select === "1")) ? 'images/complexeSelect.png' :
                                                (select === "0") ? 'images/cinema.png' :
                                                    'images/cinemaSelect.png',
                            anchor: new window.google.maps.Point(10, 10),
                            scaledSize: new window.google.maps.Size(25, 22)
                        }}
                        onClick={() => clickCine(id)}
                    >
                        {fenetreCine === id ? (
                            <InfoWindow onCloseClick={() => setFenetreCine(null)}>
                                <div style={{ color: 'black', fontSize: 10 }}>{name} (Ciné n°{id} ) <div><br></br></div>
                                    {((etape === "103B"))
                                        ? (
                                            <div style={{ color: 'black', fontSize: 10 }}>

                                                <label style={{
                                                    display: "inline-block",
                                                    color: 'black',
                                                    fontSize: 10,
                                                    width: 100,
                                                }}>
                                                    Nom du cinéma :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={name}
                                                        onChange={(e) => actuDataCine("name", id, e)}
                                                    />
                                                </label><br></br>

                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Typologie :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={typo}
                                                        onChange={(e) => actuDataCine("typo", id, e)}
                                                    />
                                                </label><br></br>

                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Code Commune :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={code_zone1}
                                                        onChange={(e) => actuDataCine("code_zone1", id, e)}
                                                    />
                                                </label><br></br>


                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Code Commune+Iris :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={code_zone2}
                                                        onChange={(e) => actuDataCine("code_zone2", id, e)}
                                                    />
                                                </label><br></br>

                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Nombre d'écrans :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={ecrans}
                                                        onChange={(e) => actuDataCine("ecrans", id, e)}
                                                    />
                                                </label><br></br>

                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Nombre de fauteuils :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={faut}
                                                        onChange={(e) => actuDataCine("faut", id, e)}
                                                    />
                                                </label><br></br>

                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Nombre de séances :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={seances}
                                                        onChange={(e) => actuDataCine("seances", id, e)}
                                                    />
                                                </label><br></br>

                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Nombre d'entrées :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={entrees}
                                                        onChange={(e) => actuDataCine("entrees", id, e)}
                                                    />
                                                </label><br></br>

                                                <label style={{ display: "inline-block", color: 'black', fontSize: 10, width: 100 }}>
                                                    Nombre de semaines :
                                                </label>
                                                <label>
                                                    <input
                                                        style={{ color: 'black', fontSize: 10, height: 20 }}
                                                        type="text"
                                                        defaultValue={sem}
                                                        onChange={(e) => actuDataCine("sem", id, e)}
                                                    />
                                                </label><br></br>
                                            </div>
                                        ) :
                                        <div style={{ color: 'black', fontSize: 10 }}>
                                            <br></br>
                                            Typologie : {typo}<br></br>
                                            Commune : {lib_zone1} ({code_zone1})<br></br>
                                            Propriétaire : {proprio}<br></br>
                                            Nombre d'écrans : {ecrans}<br></br>
                                            Nombre de fauteuils : {faut}<br></br>
                                            Nombre de semaines : {sem}<br></br>
                                            Prix moyen entrée : {pme}<br></br>
                                            Nombre de séances : {seances}<br></br>
                                            Recette : {recet}<br></br>
                                            Fréquentations : {entrees}<br></br>
                                            <br></br>
                                        </div>
                                    }

                                    {((etape === "300") ||
                                        (etape === "301"))
                                        ? (
                                            <div>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => choixPole(id)
                                                        }
                                                    />
                                                    Pole de concurrence
                                                </label><br></br>

                                                Pondération :
                                                <label>
                                                    <input
                                                        type="number"
                                                        defaultValue={ponderation}
                                                        onChange={(e) => ponder(id, e)}
                                                    />
                                                </label><br></br>
                                            </div>
                                        ) : null}

                                    <br></br>
                                </div>
                            </InfoWindow>
                        ) : null}
                    </Marker>
                ))}
            </GoogleMap>


            {
                fenetreParam ? (
                    <div className="app-header" >
                        <strong>Paramètres de l'étude</strong>
                        <br></br><br></br><br></br>

                        <div align="left">
                            <form onSubmit={handleSubmit(onSubmitParametres)}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td align="right">Nombre de semaines d'exploitation par an :</td>
                                            <td><input defaultValue={semexpl} type="number" {...register("semexpl")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right">Coefficient de majoration "offre multiplexe" :</td>
                                            <td><input
                                                defaultValue={coef_multi}
                                                step="any"
                                                type="number" {...register("coef_multi")} /></td>
                                        </tr>
                                        <tr><td>&nbsp;</td></tr>
                                        <tr>
                                            <td align="right">Facteur de distance indice de fréquentation :</td>
                                        </tr>
                                        <tr>
                                            <td align="right">Zone 1 :</td>
                                            <td><input defaultValue={dist_freq[0]} step="any" type="number" {...register("dist_freq[0]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right">Zone 2 :</td>
                                            <td><input defaultValue={dist_freq[1]} step="any" type="number" {...register("dist_freq[1]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right">Zone 3 :</td>
                                            <td><input defaultValue={dist_freq[2]} step="any" type="number" {...register("dist_freq[2]")} /></td>
                                        </tr>
                                        <tr><td>&nbsp;</td></tr>
                                        <tr>
                                            <td align="right">Facteur de distance - cinémas traditionnels :</td>
                                        </tr>
                                        <tr>
                                            <td align="right">Zone 1 :</td>
                                            <td><input defaultValue={dist_tradi[0]} step="any" type="number" {...register("dist_tradi[0]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right">Zone 2 :</td>
                                            <td><input defaultValue={dist_tradi[1]} step="any" type="number" {...register("dist_tradi[1]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right">Zone 3 :</td>
                                            <td><input defaultValue={dist_tradi[2]} step="any" type="number" {...register("dist_tradi[2]")} /></td>
                                        </tr>
                                        <tr><td>&nbsp;</td></tr>
                                        <tr>
                                            <td align="right">Coefficient d'absorption des pôles éloignés :</td>
                                            <td><input defaultValue={poleloign} step="any" type="number" {...register("poleloign")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right">Coefficient de majoration public extérieur à la ZIC :</td>
                                            <td><input defaultValue={publicext} step="any" type="number" {...register("publicext")} /></td>
                                        </tr>
                                        <tr><td>&nbsp;</td></tr>
                                        <tr>
                                            <td align="right">Taux de pénétration(TP) et Fréquentations (FR) :</td>
                                        </tr>
                                        <tr><td>&nbsp;</td></tr>
                                        <tr>
                                            <td align="right"><strong>PCS :</strong></td>
                                            <td align="right"></td>
                                            <td align="center">TP</td>
                                            <td align="center">FR</td>
                                        </tr>
                                        <tr>
                                            <td align="right"></td>
                                            <td align="right">Etud.</td>
                                            <td><input
                                                step="any"
                                                defaultValue={pcsTP[0]}
                                                type="number"
                                                {...register("pcsTP[0]"

                                                )} /></td>
                                            <td><input defaultValue={pcsFR[0]} step="any" type="number" {...register("pcsFR[0]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right"></td>
                                            <td align="right">Resatap</td>
                                            <td><input defaultValue={pcsTP[1]} step="any" type="number" {...register("pcsTP[1]")} /></td>
                                            <td><input defaultValue={pcsFR[1]} step="any" type="number" {...register("pcsFR[1]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right"></td>
                                            <td align="right">PCS +</td>
                                            <td><input defaultValue={pcsTP[2]} step="any" type="number" {...register("pcsTP[2]")} /></td>
                                            <td><input defaultValue={pcsFR[2]} step="any" type="number" {...register("pcsFR[2]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right"></td>
                                            <td align="right">PCS -</td>
                                            <td><input defaultValue={pcsTP[3]} step="any" type="number" {...register("pcsTP[3]")} /></td>
                                            <td><input defaultValue={pcsFR[3]} step="any" type="number" {...register("pcsFR[3]")} /></td>
                                        </tr>
                                        <tr><td>&nbsp;</td></tr>
                                        <tr>
                                            <td align="right"><strong>Age :</strong></td>
                                            <td align="right"></td>
                                            <td align="center">TP</td>
                                            <td align="center">FR</td>
                                        </tr>
                                        <tr>
                                            <td align="right"></td>
                                            <td align="right">6-24 ans</td>
                                            <td><input defaultValue={ageTP[0]} step="any" type="number" {...register("ageTP[0]")} /></td>
                                            <td><input defaultValue={ageFR[0]} step="any" type="number" {...register("ageFR[0]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right"></td>
                                            <td align="right">25-59 ans</td>
                                            <td><input defaultValue={ageTP[1]} step="any" type="number" {...register("ageTP[1]")} /></td>
                                            <td><input defaultValue={ageFR[1]} step="any" type="number" {...register("ageFR[1]")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right"></td>
                                            <td align="right">60 et +</td>
                                            <td><input defaultValue={ageTP[2]} step="any" type="number" {...register("ageTP[2]")} /></td>
                                            <td><input defaultValue={ageFR[2]} step="any" type="number" {...register("ageFR[2]")} /></td>
                                        </tr>
                                        <tr><td>&nbsp;</td></tr>
                                        
                                        <tr>
                                            <td align="right">Distances d'interactions :</td>
                                        </tr>
                                        <tr>
                                            <td align="right">Distance par défaut :</td>
                                            <td><input defaultValue={distInter1} step="any" type="number" {...register("setDistInter1")} /></td>
                                        </tr>
                                        <tr>
                                            <td align="right">Distance région parisienne :</td>
                                            <td><input defaultValue={distInter2} step="any" type="number" {...register("setDistInter2")} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br><br></br>
                                <div align="center">
                                    <button style={{
                                        border: 10,
                                        padding: 7,
                                        borderRadius: 7,
                                        width: 300
                                    }} type="submit">Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br></br><br></br>
                    </div>
                ) : null
            }

            {
                fenetreSeuils ? (
                    <div className="app-header">
                        <strong>Seuils des isochrones</strong>
                        <br></br><br></br>

                        {(etape !== "301") ? (<div>
                            <button
                                style={{
                                    backgroundColor: '#fdddcd',
                                    fontWeight: 'bold',
                                    border: 10,
                                    padding: 7,
                                    borderRadius: 7
                                }}
                                onClick={() => choixSeuils(1)}
                            >10 / 20  /30
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                style={{
                                    backgroundColor: '#fdddcd',
                                    fontWeight: 'bold',
                                    border: 10,
                                    padding: 7,
                                    borderRadius: 7
                                }}
                                onClick={() => choixSeuils(2)}
                            >15 / 20  /25
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                style={{
                                    backgroundColor: '#fdddcd',
                                    fontWeight: 'bold',
                                    border: 10,
                                    padding: 7,
                                    borderRadius: 7
                                }}
                                onClick={() => choixSeuils(3)}
                            >15 / 25  /35
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                style={{
                                    backgroundColor: '#fdddcd',
                                    fontWeight: 'bold',
                                    border: 10,
                                    padding: 7,
                                    borderRadius: 7
                                }}
                                onClick={() => choixSeuils(4)}
                            >15 / 25  /40
                            </button>
                            <br></br><br></br><br></br><br></br>

                        </div>) : null}

                        <div align="left">
                            <form onSubmit={handleSubmit(onSubmitSeuils)}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: 'white',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: '#fce48a',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                                ------&gt;
                                            </td>
                                            <td >
                                                <input
                                                    style={{
                                                        backgroundColor: '#cccccc',
                                                        width: '100px',
                                                        height: '30px',
                                                        fontWeight: 'bold'
                                                    }}
                                                    defaultValue={seuils[2]}
                                                    type="number" {...register("seuils[2]")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: 'white',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: '#b8caf2',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                                ------&gt;
                                            </td>
                                            <td >
                                                <input
                                                    style={{
                                                        backgroundColor: '#cccccc',
                                                        width: '100px',
                                                        height: '30px',
                                                        fontWeight: 'bold'
                                                    }}
                                                    defaultValue={seuils[1]}
                                                    type="number" {...register("seuils[1]")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: 'white',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: '#6b8de4',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                                ------&gt;
                                            </td>
                                            <td >
                                                <input
                                                    style={{
                                                        backgroundColor: '#cccccc',
                                                        width: '100px',
                                                        height: '30px',
                                                        fontWeight: 'bold'
                                                    }}
                                                    defaultValue={seuils[0]}
                                                    type="number" {...register("seuils[0]")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: 'white',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                            <td align="right">
                                                <div
                                                    style={{
                                                        backgroundColor: '#0b1634',
                                                        width: '100px',
                                                        height: '30px'
                                                    }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                            </td>
                                            <td align="right">
                                                ------&gt;
                                            </td>
                                            <td > &nbsp; 0</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br><br></br>
                                <div align="center">
                                    <button
                                        style={{
                                            border: 10,
                                            padding: 7,
                                            borderRadius: 7
                                        }}
                                        type='submit'>Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : null
            }
        </div >
    ) : <></>
}

export default React.memo(Carte)