// fenêtre tableau des données

import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// COMPOSANT
//------------------------------------------------------------------------------

var headerCines = [];

const Tableau = () => {
  // état interne
  const [content1, setContent1] = useState([]);
  const [content2, setContent2] = useState([]);

  // états partagés dans le store
  const fenetreTableau = useSelector((state) => state.cine.fenetreTableau);    // ouverture/fermeture du tableau des données
  const tableauDonnees = useSelector((state) => state.cine.tableauDonnees);    // tableau des données 

  // écoute de la mise à jour des données
  useEffect(() => {
    // création du header des poles de concurrence
    if (tableauDonnees.libelleCines.length !== 0) {
      headerCines = [];
      for (var i = 0; i < tableauDonnees.libelleCines.length; i++) {
        headerCines.push(
          <td key={i * 100}>
            <div
              style={{
                backgroundColor: "#0c6096",
                width: '100px',
                height: '100px',
                color: '#aaaaaa',
                textAlign: "center",
                fontSize: '0.7em',
                textAlign: "center"
              }} >
              {tableauDonnees.libelleCines[i].name}<br />Temps d'accès
            </div>
          </td>)

        headerCines.push(
          <td key={i * 100 + 3}>
            <div
              style={{
                backgroundColor: "#0c6096",
                width: '100px',
                height: '100px',
                color: '#aaaaaa',
                textAlign: "center",
                fontSize: '0.7em',
                textAlign: "center"
              }} >
              {tableauDonnees.libelleCines[i].name}<br />Marché absorbée
            </div>
          </td>)
      }
      setContent1(headerCines)

      // création du tableau des données
      var matrixTableau = []
      var index = 0;
      for (const items of tableauDonnees.matrix) {
        var uneLigne = []
        uneLigne.push(
          <td key={index * 100000}>
            <div
              style={{
                width: '30px',
                height: '0px'
              }} >

            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100000 + 1}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '90px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[2]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100000 + 2}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '300px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[1]}
            </div>
          </td>
        )
        uneLigne.push(
          <td key={index * 100 + 3}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[0]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100 + 4}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[3]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100 + 5}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[4]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100 + 6}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[5]}
            </div>
          </td>
        );

        uneLigne.push(
          <td key={index * 100 + 7}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[6].toFixed(2).replace('.', ',')}
            </div>
          </td>
        );

        
        // données relatives aux pôles de concurrence
        var marchPolConc = 0;
        for (var i = 0; i < tableauDonnees.libelleCines.length; i++) {
          marchPolConc += tableauDonnees.matrix[index][17][i][4];
          if (tableauDonnees.matrix[index][17][i][1] === 100) {
            uneLigne.push(
              <td key={index * 100 + i * 1000 + 100}>
                <div
                  style={{
                    backgroundColor:
                      (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                    width: '100px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'right',
                    color: 'red',
                    fontSize: '0.8em',
                    padding: '3px'
                  }}
                > plus de 30mn
                </div>
              </td>
            );
          } else {
            uneLigne.push(
              <td key={index * 100 + i * 1000 + 100}>
                <div
                  style={{
                    backgroundColor:
                      (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                    width: '100px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'right',
                    color: 'red',
                    fontSize: '0.8em',
                    padding: '3px'
                  }}
                > {tableauDonnees.matrix[index][17][i][1]}
                </div>
              </td>
            );
          };

          uneLigne.push(
            <td key={index * 100 + i * 1000 + 500}>
              <div
                style={{
                  backgroundColor:
                    (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                  width: '100px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'right',
                  fontSize: '0.8em',
                  padding: '3px'
                }}
              >{tableauDonnees.matrix[index][17][i][4]}
              </div>
            </td>
          );
        };

        uneLigne.push(
          <td key={index * 100 + 8}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {marchPolConc}
            </div>
          </td>
        );

        uneLigne.push(
          <td key={index * 100 + 8}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[7]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100 + 9}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[8]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100 + 10}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[9]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100 + 11}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[10]}
            </div>
          </td>
        );

        uneLigne.push(
          <td key={index * 100 + 11}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
               {items[10]+marchPolConc+items[9]}
            </div>
          </td>
        );

        uneLigne.push(
          <td key={index * 100 + 12}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[11]}
            </div>
          </td>
        );
        uneLigne.push(
          <td key={index * 100 + 13}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                color: 'red',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              <strong>
                {items[12]}
              </strong>

            </div>
          </td>
        );
 
        uneLigne.push(
          <td key={index * 100 + 17}>
            <div
              style={{
                backgroundColor:
                  (parseInt(index / 2) !== index / 2) ? "#ffffff" : "#eeeeff",
                width: '100px',
                height: '30px',
                display: 'flex',
                justifyContent: 'right',
                fontSize: '0.8em',
                padding: '3px'
              }} >
              {items[16].toFixed(2).replace('.', ',')}
            </div>
          </td>
        );

        matrixTableau.push(uneLigne);
        index++;
      }
      setContent2(matrixTableau)
    }
  }, [tableauDonnees]);

  // INTERFACE UTILISATEUR
  //------------------------------------------------------------------------------
  return (
    <div >
      {fenetreTableau && (<div >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong> Tableau des données : </strong>
        <br></br>
        <br></br>
        <div >
          <table >
            <tbody>
              <tr>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '30px',
                      height: '0px'
                    }} >

                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '90px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Code
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '300px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Nom de la zone
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Temps d'Accès <br></br>(T.A.)
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Population
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Zone
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    MP Théorique
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Taux de fréquentation hebdo. théorique
                  </div>
                </td>

                {content1}

                 <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Marché absorbé par pôles concurrents
                  </div>
                </td>

                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Autres cinémas: fréquentation
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Autres cinémas: fréquentation prise en compte
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Autres cinémas: marché absorbée
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Marché absorbé par poles éloignés
                  </div>
                </td>

                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Marché absorbé total
                  </div>
                </td>

                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Public extérieur à la ZIC
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Marché Potentiel final
                  </div>
                </td>
              
                <td>
                  <div
                    style={{
                      backgroundColor: "#0c6096",
                      width: '100px',
                      height: '100px',
                      color: '#aaaaaa',
                      fontSize: '0.8em',
                      textAlign: "center"
                    }} >
                    Indice de fréquentation
                  </div>
                </td>
              </tr>
              {content2.map((items, index) => {
                return (
                  <tr key={index}>
                    {items}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      )}

      <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
    </div >
  );
};

export default Tableau;
