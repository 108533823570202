import { onAuthStateChanged, signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ConnectModal from './components/ConnectModal';
import Carte from './components/Carte';
import { auth } from './utils/firebase.config';
import Tableau from './components/Tableau';

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

function App() {
  const [user, setUser] = useState(null);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    //dispatch(setCurrentUser(currentUser));
  });

  const handleLogout = async () => {
    await signOut(auth);
  }

  return (
    <>
      <div className="app-header">
        {user && (
          <div className="user-infos">
            <h4>{user?.displayName}</h4>
            <button onClick={() => handleLogout()}>
              <i className="fa-solid fa-arrow-right-from-bracket"></i>
            </button>
          </div>)
        }
        {user ? (<>

          <Carte /></>) : (<ConnectModal />)}
      </div>
        <Tableau />
    </>
  );
}

export default App;
