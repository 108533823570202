import { configureStore } from '@reduxjs/toolkit';
import cineReducer from "../features/cineSlice";

export default configureStore({
  reducer: {
    cine: cineReducer,
  },
});


