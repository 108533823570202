// redux slice

import { createSlice } from "@reduxjs/toolkit";

export const cineSlice = createSlice({
    name: "cine",
    initialState: {
        currentUser: null,
        message: "ETAPE 1 : Choix du site d'étude",        // message  fenetre header
        etape: "100",                                           // numéro du l'étape en cours
        /*                                                      100 : début de l'étude 
                                                                101 : marqueur créé - possibilité de le déplacer
                                                                102 : chargement fond de carte
                                                                103 : fond chargé
                                                                104 : cinémas chargés
                                                                200 : début étape 2 - distances-temps calculées
                                                                201 : les isochrones sont calculée
                                                                202 : valider la ZIC
                                                                300 : début étape 3
                                                                301 : sélectionner les poles pret à calculer le MP
                                                                302 : calcul terminé

        */
        echelle: true,                                      // échelle territoriale : true = communale, false = infra-communale
        longitudeSite: 1,                                   // longitude du site d'étude
        latitudeSite: 45,                                   // latitude du site d'étude
        fenetreTableau: false,                              // ouverture de la fenetre Tableau des données       
        tableauDonnees: {matrix:[], libelleCines:[] }                                  // tableau final des données

    },
    reducers: {
        setEtape: (state, { payload }) => { state.etape = payload; },
        setMessage: (state, { payload }) => { state.message = payload; },
        setLongitudeSite: (state, { payload }) => { state.longitudeSite = payload; },
        setLatitudeSite: (state, { payload }) => { state.latitudeSite = payload; },
        setCurrentUser: (state, { payload }) => {
            state.currentUser = payload;
            console.log("utilisateur enregistré");
        },
        setEchelle: (state, { payload }) => {
            state.echelle = payload;
        },
        setFenetreTableau: (state, { payload }) => { state.fenetreTableau = payload; },
        setTableauDonnees: (state, { payload }) => { state.tableauDonnees = payload; }
    },
})

export const { setMessage,
    setCurrentUser,
    setEtape,
    setEchelle,
    setLongitudeSite,
    setLatitudeSite,
    setFenetreTableau,
    setTableauDonnees
} = cineSlice.actions;
export default cineSlice.reducer;