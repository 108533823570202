// fenetre de connexion

// import librairies
import React from 'react';
import { useState } from 'react';
import Login from './Login';

// composant
const ConnectModal = () => {
    const [signUp, setSignUp] = useState(false);

    // interafce utilisateur
    return (
        <div className="connect-modal">
            <div className="header-btn">
                <button
                    style={{ background: signUp ? "rgb(10,140,140)" : "rgb(256, 256, 256)" }}
                    onClick={() => setSignUp(false)}
                >
                    Se connecter
                </button>
            </div>
           <Login />
        </div>
    );
};

export default ConnectModal;